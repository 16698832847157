import React from "react"
import { Link } from "gatsby"

import Layout from "../components/de/layout"
//import Image from "../components/image"
import SEO from "../components/de/seo"

import MainImg from "./../images/main_bg.jpg"

import shape1 from "./../images/dec/1.png"
import shape2 from "./../images/dec/2.png"
import shape3 from "./../images/dec/3.png"
import shape4 from "./../images/dec/4.png"
import shape5 from "./../images/dec/5.png"
import shape6 from "./../images/dec/6.png"
import shape7 from "./../images/dec/7.png"
import shape8 from "./../images/dec/8.png"

const Partnerstvo = () => (
  <Layout>
    <SEO
      title="MOONAMAR – PARTNER WERDEN"
      description="Liebst du die Natur und Arbeit mit Naturprodukten? Kontaktiere uns! Wir, MOONAMAR Team, freuen uns auf deine Anfrage!"
    />

    <section
      id="_heroPage"
      className="hero is-large _partnerstvo"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">PARTNERSCHAFT</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <div className="content">
                  <h2 className="subtitle is-4">
                    MOONAMAR-Produkte entstehen als Ergebnis einer Ergebenheit
                    der Natur und sorgfältiger Arbeit an Rezepturen mit
                    wohltuenden Wirkungen.
                  </h2>
                  <p>
                    Natürliche Ressourcen und das Wissen über ihre
                    Nutzungsmöglichkeiten sind praktisch unerschöpflich. Wir
                    sind uns bewusst, dass Wissen und Inspiration nicht die
                    exklusiven Rechte eines Menschen sind und von vielen
                    Menschen erworben werden. Das Kennenlernen, der Meinungs-
                    und Erfahrungsaustausch, die Zusammenarbeit und die
                    Partnerschaft gehören deshalb zu den wichtigsten Grundsätzen
                    unserer Arbeit.
                  </p>
                  <p>
                    Wir sind immer für Ratschläge, neue Ideen,
                    Produktinnovationen und natürlich für die Zusammenarbeit auf
                    allen Ebenen offen. Die Möglichkeiten einer Kooperation
                    erstrecken sich von der Konzeption und Produktentwicklung
                    über den Produktionsprozess bis zum Vertrieb.
                  </p>
                  <p>
                    <strong>
                      Liebst du die Natur, hast du Ideen und / oder Erfahrungen
                      mit der Entwicklung oder Vertrieb von natürlichen
                      Produkten? Wir freuen uns dich kennenzulernen und eine
                      Kooperation zur beiderseitigen Zufriedenheit zu
                      verwirklichen!
                    </strong>
                  </p>
                  <Link
                    to="/de/partner-werden"
                    className="button is-primary is-rounded is-small btn1head"
                  >
                    PARTNER WERDEN
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Partnerstvo
